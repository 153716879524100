import React, { useEffect, useState } from "react";

import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { TableRestaurants } from "features/restaurants/table.restaurant";
import { useGetRestaurantsMutation } from "app/services/restaurants";
import { useRestaurants } from "hooks/useRestaurants";
import AddRestaurantWin from "./windows/add.restaurant.win";
import { show } from "features/windows/slice";
import { useDispatch } from "react-redux";
import EditRestaurantWin from "./windows/edit.restaurant.win";
import { DebounceInput } from "react-debounce-input";
import { useMediaQuery } from "react-responsive";
import { setLike } from 'features/restaurants/slice'
import { FiltersWin } from "./windows/filters.window";

export const ListRestaurantsPage: React.FC = () => {
  const [ currentId, setCurrentId ] = useState<string | undefined>(undefined)
  const [ listens, setListen ] = useState("");
  const dispatch = useDispatch()
  const { filter, pagination } = useRestaurants()
  const [ getRestaurants ] = useGetRestaurantsMutation()
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  useEffect(() => { dispatch(setLike({like: listens})) }, [listens]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => { getRestaurants({filters: filter, pagination }) }, [filter]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAdd = () => dispatch(show({type: 'restaurants', name: 'add'}));
  const handleRefresh = () => getRestaurants({filters: filter, pagination });
  const handleFilters = () => dispatch(show({type: 'restaurants', name: 'filter'}));

  return (
    <>

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 140px)' }}>

        <div className="col-12 pl-0 flex flex-wrap align-items-baseline justify-content-between">
          <div className="flex flex-wrap">
            <Button label="Добавить" onClick={handleAdd} className="p-button-raised p-button-success mr-1"/>
            <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text ml-1"/>
          </div>

          <div className="p-d-flex">
            <span className="p-float-label p-input-icon-left" 
              style={{ width: isMobile ? 'calc(100vw - 76px)' : 'auto' }}
            >
              <i className="pi pi-search" />
              <DebounceInput
                minLength={0}
                debounceTimeout={1000}
                placeholder="Поиск по заведениям"
                className="p-inputtext p-component col-12"
                onChange={event => setListen(event.target.value)} />
            </span>
            <Button icon="pi pi-sliders-h" onClick={handleFilters} className="p-button-raised p-button-info p-button-text p-ml-1"/>
          </div>
        </div>

        <div className="card">
          <TableRestaurants setElementId={setCurrentId} />
        </div>

      </ScrollPanel>

      <AddRestaurantWin setElementId={setCurrentId} />
      <EditRestaurantWin restaurantId={currentId}/>
      <FiltersWin />
    </>
  );
}

export default ListRestaurantsPage;