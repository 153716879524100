import React from "react";

import { Dialog } from "primereact/dialog";
import { useWindows } from "hooks/useWindows";
import { useDispatch } from "react-redux";
import { hide } from "features/windows/slice";
import { useMediaQuery } from "react-responsive";
import MenuEditRestaurant from "./menu.edit.restaurant";
import { useRestaurants } from "hooks/useRestaurants";
import { useGetRestaurantsMutation } from "app/services/restaurants";


interface IProps {
  restaurantId: string | undefined;
}

export const EditRestaurantWin: React.FC<IProps> = (props: IProps) => {
  const { restaurantId } = props;
  const dispatch = useDispatch()
  const windows = useWindows()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const { filter, pagination } = useRestaurants()
  
  const [ getRestaurants ] = useGetRestaurantsMutation()

  const handleClose = async () => {
    await getRestaurants({filters: filter, pagination })
    dispatch(hide({type: 'restaurants', name: 'edit'}))
  };

  if(!restaurantId) return <></>

  return (
    <Dialog 
      header="Редактирование" 
      visible={windows.restaurants.edit} 
      className="col-12 p-0 md:col-10 lg:col-8"
      style={ isMobile ? {
        width: "100vw",
        height: "100vh",
        maxHeight: "100%"
      } : {} }
      onHide={handleClose}
      maximizable={isMobile ? false : true}
      contentClassName="pr-2 pl-2"
    >
      <MenuEditRestaurant restaurantId={restaurantId}/>
    </Dialog>
  );
}

export default EditRestaurantWin;
