import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import { editDishes, deleteDishes, showWindow } from 'domain/actions/restaurants.action';

import { Dialog } from "primereact/dialog";
// import DishesFormWin from "./dishes.form";
import { Form } from "react-final-form";
import { Button } from "primereact/button";
import { IDishes } from "app/interfaces/restaurants";
import { ICategory } from "app/interfaces/categories";
import { useMediaQuery } from "react-responsive";
import { useWindows } from "hooks/useWindows";
import { useGetCategoriesKitchenQuery, useGetKitchenQuery } from "app/services/requisites";
import { hide } from "features/windows/slice";
import DishesFormWin from "./components/dishes.form";
import DeleteWin from "features/windows/delete.win";
import { useDeleteDishesMutation, useUpdateDishesMutation, useUploadPhotoMutation } from "app/services/kitchen";

const formatResponse = (data: any) => {
  const dishes = {
    ...data
  }

  dishes.kitchenId = dishes.kitchenId?.id;
  dishes.categoryId = dishes.categoryId?.id;

  return dishes;
}

interface IProps {
  item: IDishes | null;
  // restaurantId: string;
  refresh: () => void;
}

export const EditDishesWin: React.FC<IProps> = (props: any) => {
  const [ file, setFile ] = useState(0);
  const [ visibleDel, setVisibleDel ] = useState(false);
  const { item, refresh } = props;
  const dispatch = useDispatch();
  const windows = useWindows();
  const [ updateDishes ] = useUpdateDishesMutation();
  const [ deleteDishes ] = useDeleteDishesMutation();
  const [ uploadPhoto ] = useUploadPhotoMutation();

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const { kitchens } = useGetKitchenQuery(null, {
    selectFromResult: ({ data }) => ({ kitchens: data }),
  });
  const { categories } = useGetCategoriesKitchenQuery(null, {
    selectFromResult: ({ data }) => ({ categories: data }),
  });
  
  const handleClose = () => dispatch(hide({type: 'kitchen', name: 'edit'}));
  const handleDelete = (event: any) => {
    event.preventDefault();
    setVisibleDel(true)
  }
  const handleDeleteDish = (item: any) => {
    deleteDishes({dishesId:item.id}).unwrap()
      .then( () => {
        handleClose()
        refresh()
      })
  }
  const onSubmit = (values: IDishes) => {
    updateDishes( formatResponse(values) ).unwrap()
      .then( (r: any) => {
        if(file) return uploadPhoto({ dishesId: values.id, file })
        return;
      } )
      .then( () => {
        handleClose()
        refresh()
      } )
  };

  const initialValues = (dishes: IDishes) => {
    const category = categories.filter( (c: ICategory) => c.id === dishes.categoryId )
    const kitchen = kitchens.filter( (k: ICategory) => k.id === dishes.kitchenId )
    
    return {
      ...dishes,
      categoryId: category.length ? category[0] : dishes.categoryId,
      kitchenId: kitchen.length ? kitchen[0] : dishes.kitchenId
    };
  }

  const dataForm = {
    name: "",
    description: "",
    photo: "",
    price: "",
    grams: "",
    categoryId: null,
    kitchenId: null
  }
  
  return (
    <>
      <Dialog 
        header="Редактирование блюда" 
        visible={windows.kitchen.edit} 
        className="col-12 p-0 md:col-10 lg:col-8"
        style={ isMobile ? {
          width: "100vw",
          height: "100vh",
          maxHeight: "100%"
        } : {} }
        onHide={handleClose}
        maximizable={isMobile ? false : true}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={item ? initialValues(item) : dataForm}
          render={({ handleSubmit }) => (
          
            <form onSubmit={handleSubmit}>
              <DishesFormWin setFile={setFile} currentImg={item.photo} categories={categories} kitchens={kitchens} />

              <div className="col-12 p-0 flex flex-wrap mt-1 mb-2 justify-content-between">
                <div className="col-12 md:col-6 p-0 flex flex-wrap justify-content-end">
                  <div className="col-12 md:col-6 p-1">
                    <Button label="Удалить" className="p-button-raised p-button-danger col-12" onClick={handleDelete}/>
                  </div>
                  <div className="col-12 md:col-6 p-1">
                    <Button label="Применить" className="col-12 p-button-raised p-button-success"/>
                  </div>
                </div>
              </div>
            </form>
          )}
        />
      </Dialog>
      
      <DeleteWin show={visibleDel} showWindow={setVisibleDel} item={item} deleteItem={() => handleDeleteDish(item) } />
    </>
    
  );
}

export default EditDishesWin;